import { ReactElement, ReactNode, useState, useEffect, useRef } from 'react'
import cn from 'classnames'
import IconInputArrow from 'images/icon-input-arrow.svg'
import IconBigInputArrow from 'images/icon-input-big-arrow.svg'
import IconLoading from 'images/icon-loader-blue.svg'
import onlyNumberChanger from 'utils/only-numbers-value'
import { formatCurrency, formatNumber } from 'utils/formatters'
import { adjustCursorPosition } from 'utils/input-cursor'

import Input from 'components/app/input'
import { NetworkLabel } from 'components/ui/network-label'

import { ICurrency, ICurrencyWithBalance } from 'store/fetchers/currencies'

import ReactClickOutside from '@coxy/react-click-outside'

import { CoinList } from '../coins-dropdown'

import { floorNumber } from '../../../../../utils/floor-number'

import { countDecimalPlaces } from '../../../../../utils/count-decimal-places'

import Notice from '../../../notice'

import styles from './styles.module.scss'

export default function InputCalculator({
  onChangeAmount,
  onChangeCoin,
  value,
  label,
  coin,
  list = [],
  children,
  isLoading,
  onEnter,
  isStableFirst = false,
  className = null,
  labelClassName = null,
  inputClassName = null,
  inputWrapperClassName = null,
  bigCurBlock = false,
  showBalance,
  listFullWidth = false,
  isExchangePage = false,
  isExchangeLayout = false,
  isVip = false,
  isNoticeShow = false,
}: {
  onChangeAmount: (value: string) => void
  onChangeCoin: (coin: ICurrency) => void
  value: string
  label: string
  coin: ICurrencyWithBalance
  list: ICurrencyWithBalance[]
  children: ReactNode
  isLoading: boolean
  onEnter?: () => void
  isStableFirst?: boolean
  className?: string
  labelClassName?: string
  inputClassName?: string
  inputWrapperClassName?: string
  bigCurBlock?: boolean
  showBalance?: boolean
  listFullWidth?: boolean
  isExchangePage?: boolean
  isExchangeLayout?: boolean
  isVip?: boolean
  balanceInUsd?: number
  isNoticeShow?: boolean
}): ReactElement {
  const [isVisibleList, setVisibleList] = useState(false)
  const isError = false
  const formattedLabel = label.replace(' ', '_').toLowerCase()
  const [formattedValue, setFormattedValue] = useState(value)
  const inputRef = useRef<HTMLInputElement>(null)
  const ref = useRef(null)

  useEffect(() => {
    const strVavue = value.toString()
    setFormattedValue(formatNumber(strVavue.replace(/\s/g, '')))
  }, [value])

  const handleChangeAmount = (event) => {
    if (coin) {
      const rawValue = event.target.value.replace(/\s/g, '')
      const parts = rawValue.split('.')
      if (parts[1]?.length > coin.decimal_places) {
        return
      }

      let cursorPosition = event.target.selectionStart
      let newValue = ''

      if (event.nativeEvent.inputType === 'deleteContentBackward') {
        cursorPosition += 1
      } else if (event.nativeEvent.inputType === 'deleteContentForward') {
        cursorPosition += 1
      } else {
        cursorPosition -= 1
      }

      onlyNumberChanger(rawValue, (newRawValue) => {
        newValue = newRawValue

        const newFormattedValue = formatNumber(newValue)
        setFormattedValue(newFormattedValue)

        setTimeout(() => {
          if (inputRef.current) {
            const newCursorPosition = cursorPosition - (formattedValue.length - newFormattedValue.length)
            inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition)
          }
        }, 0)
      })
      onlyNumberChanger(rawValue, onChangeAmount)
    }
  }

  const handleCoinListClose = () => {
    setVisibleList(false)
  }

  const handleChooseCoin = (coin) => {
    onChangeCoin(coin)
    handleCoinListClose()
  }

  return (
    <div className={cn(styles.changeCoinFrom, className, { [styles.exchangePage]: isExchangePage })}>
      <label
        className={cn(styles.name, labelClassName, { [styles.exchangeLayout]: isExchangeLayout })}
        htmlFor={formattedLabel}
      >
        {label}{' '}
        {isNoticeShow && (
          <Notice leftPos="-50px" alt="repayment amount notice" wrapperClassName={styles.notice}>
            Please note, this amount does not include network fees and processing fees. These fees will be calculated
            and added to the repayment amount automatically in the next step
          </Notice>
        )}
      </label>
      <div
        className={cn(
          styles.inputBox,
          inputWrapperClassName,
          { [styles.inputBox_exchangeLayout]: isExchangeLayout },
          { [styles.inputBoxOpened]: isVisibleList },
          { [styles.inputErrorValue]: isError },
        )}
      >
        <Input
          ref={inputRef}
          className={cn(
            styles.inputField,
            inputClassName,
            { [styles.inputFieldOpened]: isVisibleList },
            { [styles.exchangeLayout]: isExchangeLayout },
          )}
          onEnter={onEnter}
          value={isLoading ? '' : formattedValue}
          onChange={handleChangeAmount}
          onKeyDown={adjustCursorPosition}
          onMouseUp={adjustCursorPosition}
          id={formattedLabel}
        />
        {isLoading && <img src={IconLoading} className={styles.loader} alt="loading" />}
        {children}
        <div ref={ref} className={styles.openListButton} onClick={() => setVisibleList((st) => !st)}>
          {coin && coin.code && (
            <div
              className={cn(
                styles.currency,
                { [styles.currency_bigMode]: bigCurBlock },
                { [styles.exchangePage]: isExchangePage },
                { [styles.vip]: isVip },
              )}
            >
              <img
                className={cn(
                  styles.currencyIcon,
                  { [styles.currencyIcon_bigMode]: bigCurBlock },
                  { [styles.exchangePage]: isExchangePage },
                )}
                alt={coin.code}
                src={coin.logo_url}
                width={bigCurBlock ? 36 : 20}
                height={bigCurBlock ? 36 : 20}
              />
              <div className={cn(styles.row, styles.column)}>
                <div className={styles.row}>
                  <div>{formatCurrency(coin, true)}</div>
                  <NetworkLabel
                    className={cn(styles.currencyNetwork, { [styles.currencyNetwork_bigMode]: bigCurBlock })}
                    currency={coin}
                  />
                </div>
                <div className={cn(styles.balance, { [styles.balance_bigMode]: bigCurBlock })}>
                  {showBalance
                    ? coin?.balance && coin.balance > 0
                      ? floorNumber(+Number(coin.balance).toFixed(countDecimalPlaces(coin?.balance)), 6)
                      : coin.name
                    : ''}
                </div>
              </div>
            </div>
          )}
          {list.length > 0 && (
            <img
              src={bigCurBlock ? IconBigInputArrow : IconInputArrow}
              className={cn({ [styles.openedListArrow]: isVisibleList }, { [styles.iconArrow]: bigCurBlock })}
              alt="IconInputArrow"
              width={8}
              height={4}
            />
          )}
        </div>

        <ReactClickOutside onClose={handleCoinListClose} visible={isVisibleList}>
          <CoinList
            list={list}
            showBalance={showBalance}
            selected={coin}
            onChoose={handleChooseCoin}
            isStableFirst={isStableFirst}
            listFullWidth={listFullWidth}
            isExchangeLayout={isExchangeLayout}
            isVip={isVip}
            isVisibleList={isVisibleList}
          />
        </ReactClickOutside>
      </div>
    </div>
  )
}
